<template>
  <div class="PageBox">

    <div class="topDiv">
      <div class="login_text1">{{$t('login.text1[4]')}}</div>
      <div class="login_text2">{{$t('login.text1[1]')}}</div>
    </div>
    <!-- <div class="logo">
      {{$t('home.label[0]')}}
    </div> -->
    <div class="ScrollBox loginpanel">
      <div v-show="reg_type==0" class="regType">
        <h2>{{$t('register2[0]')}}</h2>
        <van-button  size="mini" @click="changeType(1)" style="font-size: 18px"
                    native-type="button">
          {{$t('register2[1]')}}</van-button>
      </div>
      <div v-show="reg_type==1" class="regType">
        <van-button  size="mini" @click="changeType(0)" style="font-size: 20px"
                    native-type="button">
          {{$t('register2[0]')}}</van-button>
        <h2>{{$t('register2[1]')}}</h2>
      </div>
      <van-field  :label="$t('login.text2[0]')" clearable :border="false" v-if="reg_type==0">
        <template #input>
          <input
                  v-model.trim="postData.username"
                  :placeholder="$t('register.placeholder[0]')"
                  style="border: 0; flex: 1; width: 100px; background: transparent"
          />
        </template>
      </van-field>
      <van-field :label="$t('login.text2[4]')" clearable :border="false" v-if="reg_type==1">
        <template #input>
          <van-dropdown-menu>
            <van-dropdown-item :title="`+${postData.dest}`" ref="DropdownItem">
              <template>
                <ul>
                  <li v-for="item in areaList" style="color: #aaa"
                      :key="item.id" :class="{on:postData.dest==item.id}"
                      @click="postData.dest=item.id,$refs.DropdownItem.toggle()">
                    <span>+{{item.id}}&#12288;{{item.name}}</span></li>
                </ul>
              </template>
            </van-dropdown-item>
          </van-dropdown-menu>
          <input type="tel" v-model.trim="postData.username" :placeholder="$t('register2[2]')" style="border: 0; flex: 1; width: 100px; background: transparent"
          />
        </template>
      </van-field>
      <van-field
              :label="$t('login.text2[1]')"
              v-model.trim="postData.smscode"
              autocomplete="off"
              type="digit"
              :placeholder="$t('register.placeholder[1]')"
              clearable
              :border="false"
      >
        <template #button>
          <van-button style="margin-right:-30px;width: 100px"
                      size="mini"
                      color="#59D4E4"
                      round
                      native-type="button"
                      @click="getSmsCode"
                      :loading="isSendCode"
                      :loading-text="sendText"
          >{{ $t("register.text[2]") }}</van-button
          >
        </template>
      </van-field>
      <van-field
              :label="$t('login.text2[2]')"
              type="password"
              v-model.trim="postData.password"
              :placeholder="$t('register.placeholder[2]')"
              clearable
              autocomplete="off"
              :border="false"
              :type="showPass1 ? 'text' : 'password'"
              :right-icon="showPass1 ? 'eye' : 'closed-eye'"
              @click-right-icon="showPass1 = !showPass1"
      />
      <van-field
              :label="$t('login.text2[2]')"
              v-model.trim="postData.re_password"
              :placeholder="$t('register.placeholder[3]')"
              clearable
              autocomplete="off"
              :border="false"
              :type="showPass ? 'text' : 'password'"
              :right-icon="showPass ? 'eye' : 'closed-eye'"
              @click-right-icon="showPass = !showPass"
      />
      <van-field
              :label="$t('login.text2[3]')"
              :readonly="recommendDis"
              v-model.trim="postData.recommend"
              :placeholder="$t('register.placeholder[4]')"
              clearable
              autocomplete="off"
              :border="false"
      />
      <div class="h12"></div>
              <router-link class="href" slot="a" to="/login">{{
          $t("register.label[1]")
          }}</router-link>
      <Loading />
      <div style="padding: 33px 16px;text-align: center">
        <p class="btn" @click="onSubmit()">{{ $t('register.text[4]') }}</p>

      </div>
    </div>
    <div class="BrowserTips" v-show="showBrowserTips">
      <img :src="'./static/images/down-zy.png'" width="80%" />
    </div>
    <div
            style="text-align: center; width: 380px; height: 260px"
            id="captcha"
    ></div>
  </div>
</template>
<script>
  import $ from "jquery";

  import fpjsfpjs from "@fingerprintjs/fingerprintjs";
  import config from "@/config";
  import $Dialog from "../common/Dialog";

  let Language = config.Language;
  const AppDown = config.AppDown;
  export default {
    name: "Register",
    components: {},
    props: ["recommendId", "appRegister"],
    data() {
      return {
        postData: {
          dest: 84, //表示英国
          username: "",
          password: "",
          re_password: "",
          smscode: "",
          code: "",
          verify_data: "",
          code_rand: "",
          fp: "dft",
          submit_key: "",
          rkey: null,
          recommend: this.recommendId || "",
          uniqueID: localStorage["phoneId"] || "", //手机唯一标识
        },
        sendText:'',
        reg_type:0,
        recommendDis: this.recommendId ? true : false,
        countdown:120,
        timer:null,
        areaList: [
          {
            id: "86",
            name: "CHINA(中国)",
          },
        ],
        submitRegister: "",
        showSlideCapche: false,
        isSendCode: false,
        isSubmit: false,
        codeImg: "",
        testtt: "aaa",
        showBrowserTips: false,
        downUrl: "",
        isPlus: false,
        showPass1: false,
        showPass: false,
        searchValue: "",
        dests:{"vn":"84","en":"84"},
        copyAreaList: [],
      };
    },
    computed: {},
    created() {
      console.log("pro1 " + this.recommendId);
      console.log("pro2 " + this.appRegister);
      console.log(this.postData.dest);
    },
    mounted() {
      this.$Util.Loading(false);
      if (window.plus) {
        this.isPlus = true;
      }
      this.sendText = this.$t('register.text[1]');
      this.$Model.SmsCode((data) => {
        this.areaList = data
        this.postData.dest = "84"
      })
      console.log(this.postData.recommend);
    },
    activated() {},
    destroyed() {},
    beforeDestroy() {
      console.log("clear timer");
      clearInterval(this.timer);
    },
    watch: {},
    methods: {
      changeType(reg_type){
        this.reg_type=reg_type;
        this.timer = null;
        clearInterval(this.timer);
        console.log("change type");
        const lang = localStorage["Language"] || "en";
        if (this.reg_type==1&&this.dests[lang]){
          this.postData.dest = this.dests[lang];
        }else{
          this.postData.username = null
        }
      },
      getCode() {
        this.postData.code_rand = new Date().getTime();
        this.codeImg =
                this.ApiUrl + "/api/Account/code?code_rand=" + this.postData.code_rand;
      },
      onSubmit() {
        if (!this.postData.username) {
          this.$Dialog.Toast(this.$t("register.placeholder[0]"));
          return;
        }
        if(!this.postData.recommend){
          this.$Dialog.Toast(this.$t('register.placeholder[4]'))
          return
        }
        if (!this.postData.smscode) {
          this.$Dialog.Toast(this.$t("register.placeholder[1]"));
          return;
        }
        if (!this.postData.password) {
          this.$Dialog.Toast(this.$t("register.placeholder[2]"));
          return;
        }
        if (!this.postData.re_password) {
          this.$Dialog.Toast(this.$t("register.placeholder[3]"));
          return;
        }
        if (this.postData.password != this.postData.re_password) {
          this.$Dialog.Toast(this.$t("register.placeholder[5]"));
          return;
        }

        this.isSubmit = true;
        this.$Model.UserRegister(this.postData, (data) => {
          this.isSubmit = false;
          if (data.code == 1) {
            this.$Dialog.Toast(this.$t('common3[0]'));
            this.$router.replace("/login?username="+this.postData.username);
          }else{
            let idx = data.code_dec;
            let msg = this.$t("register.codes['"+idx+"']");
            this.$toast(msg);
          }
        });
      },
      settime() {
        var that = this;
        console.log("timer"+that.countdown);
        if (that.countdown == 0) {
          that.sendText = that.$t('register.text[1]');
          that.countdown = 120;
          that.isSendCode = false;
          clearTimeout(that.timer)
          return;
        } else {
          that.sendText = "("+that.countdown+")";
          console.log("set text:"+that.sendText);
          that.countdown--;
        }
        that.timer = setTimeout(function() {
          that.settime()
        },1000)
      },
      getSmsCode() {
        var reg = /^[0-9]+$/;
        let email = this.postData.username;
        let a = reg.test(email);
        if (!email) {
          this.$Dialog.Toast(this.$t("register.placeholder[0]"));
          return;
        }else if ((this.reg_type==0)&&(email.split("@").length==1 || email.split(".").length==1)) {
          this.$Dialog.Toast(this.$t("register.placeholder[7]"));
          return;
        }else if (this.reg_type==1&&!reg.test(email)) {
          this.$Dialog.Toast(this.$t("register2[2]"));
          return;
        }
        this.settime();
        this.isSendCode = true;
        this.$Model.GetRegCode(
                {
                  reg_type:this.reg_type,
                  mail: this.postData.username,
                  code: this.postData.code,
                  dest: this.postData.dest,
                },
                (data) => {
                  if (data.code == 0) {
                    $Dialog.Toast(data.code_dec);
                  }
                }
        );
      },
    },
  };
</script>

<style scoped lang="scss">
.topDiv{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 260px;
  background: url(~@/assets/img/new/login_top.png) no-repeat;
  background-size: 100%  auto;
  .login_text1{
    font-size: 28px;
    font-weight: 600;
    color: #000;
    margin-bottom: 4px;
    margin-top: 100px;
    margin-left: 32px;
  }
  .login_text2{
    font-size: 16px;
    color: #000;
    margin-left: 32px;
  }
}

  .PageBox {
    color: #635327;
    padding: 0;
    background-color: #FFFFFF;
  }

  .regType {
    margin: 20px 0;
    color:#999;
    display: flex;
    padding: 10px;
    button{
      width: 50%;
      text-align: center;
      border: none !important;
      color:#999;
    }
    h2{
      width: 50%;
      display: inline-block;
      text-align: center;
      position: relative;
      color:#333;
      font-size: 19px;
      &::after{
        content:'';
        height: 6px;
        border-radius: 3px;
        width: 50px;
        left: calc(50% - 25px);
        bottom: 0;
        background: #52D2E5;
        position: absolute;
      }
    }
  }


  .van-dropdown-menu ::v-deep .van-dropdown-menu__bar {
    height: auto;
    box-shadow: none;
    background: transparent;
  }

  .van-dropdown-menu ::v-deep .van-dropdown-menu__title {
    padding: 0 10px 0 0;
    margin-right: 10px;
    color: #D7D7D7;
  }

  .van-field ::v-deep .van-dropdown-menu ::v-deep .van-dropdown-item__content ul li {
    padding: 0 15px;
    height: 254px !important;
    color: #eee;
  }

  .van-dropdown-menu ::v-deep .van-dropdown-item__content ul li {
    padding: 4px 0;
    width: 100%;
    font-size: 14px;
    background-color: #24303C;
    color: #eee;
  }

  .van-dropdown-menu ::v-deep .van-dropdown-item__content ul li.on {
    color: #eee;
  }
  .van-dropdown-menu ::v-deep .van-dropdown-item__content ul li span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .van-dropdown-menu ::v-deep .van-dropdown-item__content ul li + li {
    color: #333;
  }

  .BrowserTips {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: right;
    z-index: 99999;
  }

  .van-nav-bar ::v-deep .van-nav-bar__text {
    color: #999;
  }

  .slidercaptcha .card-body {
    padding: 1rem;
  }

  .slidercaptcha canvas:first-child {
    border-radius: 4px;
    border: 1px solid #e6e8eb;
  }

  .slidercaptcha.card .card-header {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.03);
  }

  .refreshIcon {
    top: -54px;
  }

  .href {
    color: #52D2E5;
    margin-left: 20px;
  }

  .header_bg {
    width: 100%;
    z-index: 0;
  }

  .register_title {
    font-size: 27px;
    color: #F46926;
    z-index: 10;
    padding-top: 210px;
    padding-left: 50px;
    font-family: Alibaba;
  }

  .logo {
    width: 100%;
    font-size: 25px;
    color: #fff;
    text-align: left;
    margin-top: 30px;
    margin-left: 30px;
  }

  .loginpanel {
    background: #fff;
    width: 100%;
    padding-bottom: 60px;
    position: relative;
    z-index: 11;
    top: 180px;
    border-radius: 16px 16px 0 0;
  }

  .logo img {
    margin: 20px auto;
    text-align: center;
    width: 200px;
  }
  .van-form {
    padding: 0 35px;
  }

  .van-cell--borderless {
    width: calc(100% - 5px);
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    color:#fff;
    margin-top: 15px;
    margin-left: 2px;
    padding: 0 30px 0 8px;
    height: 45px;
    border-bottom: 1px solid #f1f1f1;
  }
  ::v-deep  .van-field__label{
    line-height: 45px !important;
    font-size: 15px;
    color: #333;
    width: 90px;
  }

  .van-cell--borderless ::v-deep .van-icon {
    font-size: 25px !important;
    color: #fff !important;
    margin-top: 8px;
  }

  .van-cell--borderless ::v-deep .van-field__right-icon .van-icon-closed-eye {
    font-size: 21px !important;
    color: #fff !important;
    margin-right:-10px;
  }

  .van-button--danger {
    max-width: 200px;
    margin: auto;
    font-family: Alibaba;
    font-size: 15px !important;
    font-weight: bold;
    background: linear-gradient(90deg, #fff, #fff);
    color: #ccc;
    border-radius: 1.2rem;
    height: 46px;
  }

  .van-cell--borderless ::v-deep .van-field__right-icon .van-icon-eye {
    font-size: 18px !important;
    color: #fff !important;
    margin-right:-10px;
  }

  .van-cell--borderless ::v-deep .van-field__control {
    padding-left: 0px;
  }

  .van-nav-bar ::v-deep .van-nav-bar__text {
    color: #333;
    font-size: 15px;
    font-family: Alibaba;
    position: relative;
  }

  .Site .van-nav-bar ::v-deep .van-icon {
    color: #333;
  }

  .van-search__content {
    padding: 0;
  }

  .van-search__content ::v-deep .van-cell {
    margin: 0;
    padding-left: 15px;
  }

  .PageBox ::v-deep .van-dropdown-item {
  }

  .PageBox ::v-deep .van-overlay {
    background-color: transparent;
  }

  .goLo a {
    color: #fe889d;
  }

  .PageBox ::v-deep .van-popup ul > li {
    font-size: 17px !important;
    display: flex;
    justify-content: space-between;
  }

  .PageBox ::v-deep .van-popup .van-search__action div {
    width: 22px;
    display: flex;
    align-items: center;
  }

  .van-search__content ::v-deep .van-cell {
    height: 35px;
    border-radius: 1rem;
  }

  .van-search__content ::v-deep .van-icon {
    display: none;
  }

  .van-search {
    padding: 20px 0 6px;
  }

  .PageBox ::v-deep .van-search__action div img {
    width: 100%;
  }

  .van-cell ::v-deep .van-field__control::-webkit-input-placeholder,
  .van-cell ::v-deep .van-field__control > input::-webkit-input-placeholder {
    color: #939393 !important;
    font-size: 16px;
    font-family: Alibaba;
  }

  .van-cell ::v-deep .van-field__control::-moz-placeholder,
  .van-cell ::v-deep .van-field__control > input::-moz-placeholder {
    color: #939393 !important;
    font-size: 19px;
    font-family: Alibaba;
  }

  .van-cell ::v-deep .van-field__control:-ms-input-placeholder,
  .van-cell ::v-deep .van-field__control > input::-ms-input-placeholder {
    color: #939393 !important;
    font-size: 18px;
    font-family: Alibaba;
  }

  /* .van-cell::v-deep.van-dropdown-menu::-webkit-input-placeholder{
          color: #777;
          font-size: 19px;
          font-family: Alibaba;
      } */
  .van-cell ::v-deep .van-cell__value,
  .van-cell ::v-deep .van-field__body,
  .van-cell ::v-deep .van-field__control,
  .van-cell ::v-deep .van-dropdown-menu,
  .van-cell ::v-deep input {
    height: 100%;
  }

  .van-cell ::v-deep input {
    color: #333 !important;
    padding-left: 7px !important;
    height: 100%;
    font-size: 18px;
  }

  .van-cell ::v-deep .van-ellipsis {
    font-size: 18px;
    line-height: 45px;
    color: #333;
  }

  .van-cell ::v-deep .van-dropdown-menu__title::after {
    background-size: 100% 100%;
    width: 9px;
    height: 5px;
    border: none;
    transform: rotate(0deg);
    top: 62%;
    right: -7px;
  }

  .goLo {
    text-align: left;
    color: rgb(78, 81, 191);
    margin-top: 20px;
    font-size: 15px;
    font-family: Alibaba;
  }

  .van-nav-bar ::v-deep .van-nav-bar__text::after {
    content: "";
    position: absolute;
    background-size: 100% 100%;
    width: 9px;
    height: 5px;
    top: 9px;
    right: -15px;
  }

  .Site ::v-deep .van-nav-bar__right {
    padding-right: 40px;
  }
  .van-cell ::v-deep .van-field__button .van-button--info {
    background-color: #ccc;
    border: none;
  }


  .PageBox a:link {
    color: #635327;
  }

  .btn {
    width: calc(100% - 20px);
    border-radius: 24px;
    height: 48px;
    line-height: 48px;
    background: linear-gradient(45deg,#8AE8F3,#3FC4D5);
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 15px 10px;
  }
</style>
